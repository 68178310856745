<template>
  <div id="user-view">
    <v-form
      ref="formRef"
      @submit.prevent="saveData"
    >
      <v-card class="mb-5">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="8"
            sm="6"
            class="pa-0"
          >
            <v-card-title>Haupt-Einstellungen</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.mainData.kundenKennung"
                    label="Kennung"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Kennung"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="form.mainData.vorname"
                    label="Vorname"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Vorname"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="form.mainData.name"
                    label="Name"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col
            cols="4"
            sm="6"
          >
            <div>
              <v-img
                width="120"
                src="@/assets/images/misc/tree-4.png"
                class="gamification-tree-4"
              ></v-img>
              <v-img
                width="80"
                src="@/assets/images/3d-characters/pose-2.png"
                class="gamification-john-pose-2"
              ></v-img>
              <v-img
                width="100"
                src="@/assets/images/misc/tree.png"
                class="gamification-tree"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mb-5">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            sm="12"
            class="pa-0"
          >
            <v-card-title>Weitere Angaben</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="form.mainData.email"
                    label="E-Mail"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="E-Mail"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="form.mainData.geburtstag"
                    label="Geburtstag"
                    type="date"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Geburtstag"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" md="4">
                  <v-select
                    v-model="form.mainData.geschlecht"
                    :items="geschlechtOptions"
                    item-text="label"
                    item-value="value"
                    label="Geschlecht"
                    outlined
                    dense
                    hide-details
                    placeholder="Geschlecht"
                  ></v-select>
                </v-col>

                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="form.mainData.plz"
                    label="PLZ"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="PLZ"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="form.mainData.ort"
                    label="Ort"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Ort"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" md="4">
                  <v-select
                    v-model="form.mainData.land"
                    :items="countries"
                    item-text="label"
                    item-value="value"
                    label="Land"
                    outlined
                    dense
                    hide-details
                    placeholder="Land"
                  ></v-select>
                </v-col>

                <v-col cols="4" md="4">
                  <v-select
                    v-model="form.mainData.sprache"
                    :items="languages"
                    item-text="label"
                    item-value="value"
                    label="Sprache"
                    outlined
                    dense
                    hide-details
                    placeholder="Sprache"
                  ></v-select>
                </v-col>

                <v-col cols="4" md="4">
                  <v-select
                    v-model="form.mainData.plattform"
                    :items="platforms"
                    item-text="label"
                    item-value="value"
                    label="Plattform"
                    outlined
                    dense
                    hide-details
                    placeholder="Plattform"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mb-5">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            sm="12"
            class="pa-0"
          >
            <v-card-title>Zuordnungen</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4" md="4">
                  <v-select
                    v-model="form.mainData.idNipKanal"
                    hide-details="auto"
                    outlined
                    dense
                    :items="channels"
                    item-text="displayName"
                    item-value="idNipKanal"
                    label="Auswahl Kanal"
                  ></v-select>
                </v-col>
                <v-col cols="4" md="4">
                  <v-select
                    v-model="form.assignmentData.topics"
                    multiple
                    hide-details="auto"
                    outlined
                    dense
                    chips
                    :items="topics"
                    item-text="displayName"
                    item-value="idNipThemenbereich"
                    label="Auswahl Themenbereiche"
                  ></v-select>
                </v-col>
                <v-col cols="4" md="4">
                  <v-select
                    v-model="form.assignmentData.tags"
                    multiple
                    hide-details="auto"
                    outlined
                    dense
                    chips
                    :items="tags"
                    item-text="displayName"
                    item-value="idNipTag"
                    label="Auswahl Tags"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mb-5">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            sm="12"
            class="pa-0"
          >
            <v-card-title>Attribute</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4" md="4" v-for="index in 40">
                  <v-text-field
                    v-model="form.attribute[`a${index}`]"
                    :label="'Attribut '+index"
                    :placeholder="'Attribut '+index"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="mt-5">
        <v-col cols="12" md="12">
          <router-link :to="'/empfaenger'">
            <v-btn
              color="error"
              class="mx-2"
            >
              Zurück
            </v-btn>
          </router-link>
          <v-btn color="primary" type="submit" @click="saveData">
            Speichern
          </v-btn>
          <v-btn
            type="reset"
            outlined
            class="mx-2"
          >
            Zurücksetzen
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {onMounted, ref, getCurrentInstance,nextTick} from '@vue/composition-api'
import router from '@/router'
import {
  mdiHomeOutline,
  mdiEmailOutline,
  mdiRefresh
} from '@mdi/js'

import axios from "axios";
import {emailValidator, required} from "@core/utils/validation";
import InlineDatePicker
  from "@/views/InlineDatePicker";

export default {
  components: {
    InlineDatePicker
  },
  data() {
    return {
      geschlechtOptions: [
        {label: 'Männlich', value: "m"},
        {label: 'Weiblich', value: "w"},
        {label: 'Divers', value: "d"},
      ],
      countries: [
        {label: 'Deutschland', value: 'de'},
        {label: 'Österreich', value: 'at'},
        {label: 'Schweiz', value: 'ch'},
      ],
      languages: [
        {label: 'deutsch', value: 'de'},
        {label: 'österreichisch', value: 'at'},
        {label: 'schweizerisch', value: 'ch'},
      ],
      platforms: [
        {label: 'windows', value: 'windows'},
        {label: 'mac', value: 'mac'},
        {label: 'android', value: 'android'},
        {label: 'ios', value: 'ios'},
      ],
    }
  },
  setup() {

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 2000;

    const formRef = ref(null)
    const form = ref({})
    const channels = ref([]);
    const topics = ref([]);
    const tags = ref([]);

    form.value = {
      mainData : {
        idNipAbonnent : router.currentRoute.params.idNipAbonnent,
        idNipKanal : 0,
        kundenKennung : '',
        vorname : '',
        name : '',
        email : '',
        geschlecht : '',
        land : '',
        plz : '',
        ort : '',
        geburtstag : '',
        sprache : '',
        plattform : '',
      },
      attribute : {
        a1:"", a2:"", a3:"", a4:"", a5:"", a6:"", a7:"", a8:"", a9:"", a10:"",
        a11:"", a12:"", a13:"", a14:"", a15:"", a16:"", a17:"", a18:"", a19:"", a20:"",
        a21:"", a22:"", a23:"", a24:"", a25:"", a26:"", a27:"", a28:"", a29:"", a30:"",
        a31:"", a32:"", a33:"", a34:"", a35:"", a36:"", a37:"", a38:"", a39:"", a40:"",
      },
      assignmentData: {
        topics: {},
        tags: {},
      },
      aktion: 'editSubscriberCheck'
    };
    const loading = ref(true)

    onMounted(()=>{

      axios.get('/api/ajaxServer/?aktion=fetchChannels').then((response) => channels.value = response.data.channels)
      axios.get('/api/ajaxServer/?aktion=fetchTopics').then((response) => topics.value = response.data.topics)
      axios.get('/api/ajaxServer/?aktion=fetchTags').then((response) => tags.value = response.data.tags)

      axios.post('/api/empfaenger/', {
        aktion: 'editSubscriber',
        mainData:{
          idNipAbonnent : router.currentRoute.params.idNipAbonnent,
        }
      })
        .then((response) => {
          if (response.data.valid === true) {
            form.value.mainData = response.data.subscriber;
            form.value.assignmentData.tags = response.data.tagList;
            form.value.assignmentData.topics = response.data.topicList;
            form.value.attribute = response.data.attribute;
          }
          else {
            response.data.errors.forEach(item=>{
              let suffix = item.errorField !== "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loading.value = false;
        })
        .catch((error) => {
          console.log(error)
          loading.value = true;
        })
    })

    const saveData = () => {
      const isFormValid = formRef.value.validate()
      if(!isFormValid) return
      loading.value = true;

      const fd = new FormData();
      fd.append('aktion', form.value.aktion);
      fd.append('idNipAbonnent', form.value.mainData.idNipAbonnent);
      fd.append('mainData', encodeURIComponent(JSON.stringify(form.value.mainData)));
      fd.append('attribute', encodeURIComponent(JSON.stringify(form.value.attribute)));
      fd.append('tags', encodeURIComponent(JSON.stringify(form.value.assignmentData.tags)));
      fd.append('topics', encodeURIComponent(JSON.stringify(form.value.assignmentData.topics)));

      axios
        .post('/api/empfaenger/',fd)
        .then(response => {
          if(response.data.valid === true){
            router.push('/empfaenger',()=>{
              vm.$toastr.s(response.data.message.text, response.data.message.title);
            })
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField !== "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loading.value = false;
        })
    }

    return {
      form,
      formRef,
      saveData,
      channels,
      topics,
      tags,
      loading,
      icons: {
        mdiHomeOutline,
        mdiEmailOutline,
        mdiRefresh,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },

}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}
</style>
